import React, { useState } from 'react'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
// import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import Alert from '@mui/material/Alert'
// import Radio from '@mui/material/Radio'
// import RadioGroup from '@mui/material/RadioGroup'
// import FormControlLabel from '@mui/material/FormControlLabel'
// import FormLabel from '@mui/material/FormLabel'

import epassilogo from '../epassi_logo.png'
import smartumlogo from '../smartum_logo.svg'
// import eazybreaklogo from '../eazybreak_logo.png'
import '../header.css'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import { purple } from '@mui/material/colors'
import axios from 'axios'
axios.defaults.baseURL = 'https://us-central1-capper-aa9ce.cloudfunctions.net'
// axios.defaults.baseURL = 'http://localhost:5001/capper-aa9ce/us-central1'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

// const SmartumButton = styled(Button)(({ theme }) => ({
//   color: theme.palette.getContrastText(red[700]),
//   backgroundColor: red['A700'],
//   '&:hover': {
//     backgroundColor: red[900],
//   },
// }))

const EpassiButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  '&:hover': {
    backgroundColor: purple[700],
  },
}))

const clubs = [
  {
    group: 'tennis',
    name: 'Tuusulan Tennisseura',
    value: 'tts',
    logo: 'https://suomentennisliitto.kuvat.fi/kuvat/Seuralogoja/426574_555914581115935_955017319_n.jpg?img=smaller',
  },
  { group: 'tennis', name: 'Valkeakosken Tennis', value: 'vt', logo: '' },
  // { group: 'tennis', name: 'Variston Tennisseura', value: 'varte', logo: '' },
  // { group: 'tennis', name: 'Espoon Verkkopalloseura', value: 'evs', logo: '' },
  // { group: 'tennis', name: 'HVS-Tennis', value: 'hvs', logo: '' },
  { group: 'tennis', name: 'Valko-Pallo', value: 'vp', logo: '' },
  // { group: 'tennis', name: 'Tampereen Tennisseura', value: 'tats', logo: '' },
  // { group: 'sulkapallo', name: 'Espoon Sulkapallo-Badminton', value: 'esb', logo: '' },
]

function HomeScreen() {
  const [club, setClub] = useState()
  const [invoiceid, setInvoiceid] = useState('')
  const [reference, setReference] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [amount, setAmount] = useState(0)
  // eslint-disable-next-line no-unused-vars
  const [note, setNote] = useState('')
  const [sportmoney, setSportmoney] = useState('')
  const [saving, setSaving] = useState(false)
  const [errors, setErrors] = useState(null)
  const [debug, setDebug] = useState()

  const savePaymentDraft = async (event, sportm) => {
    event.preventDefault()
    setSaving(true)
    const paymentDraft = {
      club,
      invoiceid,
      reference,
      name,
      email,
      amount,
      sportmoney: sportm,
      note,
    }
    try {
      const response = await axios.post('/payment?pm=' + sportm, paymentDraft)
      console.log(response)
      setSaving(false)
      setDebug(response.data.toString())
      if (sportm === 'epassi') {
        document.open()
        document.write(response.data)
        document.close()
      } else if (sportm === 'smartum') {
        window.location.href = response.data.data.url
      }
    } catch (error) {
      console.log(error)
      setErrors(error)
      setSaving(false)
    }
  }

  const valid = club && invoiceid && reference && name && phone && amount && sportmoney
  const showDebug = debug || errors

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth='sm'>
        <Paper>
          <Grid
            container
            spacing={0}
            direction='column'
            alignItems='center'
            justifyContent='center'
            style={{ marginTop: 30 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div style={{ backgroundImage: `url('header.png')`, minHeight: '150px' }}>
                  <div className='headerlogod' style={{ marginLeft: 10 }}>
                    <img src='https://www.cintoia.com/img/logo.png' alt='logo' />
                  </div>
                  <div className='headertext' style={{ fontSize: 'x-large', textAlign: 'right', marginRight: 10 }}>
                    Laskun maksaminen
                    <br />
                    liikuntarahalla
                  </div>
                </div>
                {/* <div className='wrapper'>
                  <div className='base one'></div>
                  <div className='base two'></div>
                  <div className='base three'></div> */}
                {/* <div className='headerlogod'><img src="https://www.cintoia.com/img/logo.png" alt="logo" /></div> */}
                {/* 
                </div> */}
              </Grid>
              <Grid item xs={12}>
                <Item>
                  <FormControl fullWidth>
                    <InputLabel id='demo-simple-select-label'>Seura</InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={club ? club : ''}
                      label='Seura'
                      onChange={(e) => setClub(e.target.value)}
                    >
                      {clubs
                        .sort((a, b) => {
                          if (a.name < b.name) return -1
                          if (a.name > b.name) return 1
                          return 0
                        })
                        .map((c) => (
                          <MenuItem key={c.value} value={c.value}>
                            {' '}
                            {c.name}{' '}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Item>
              </Grid>
              {club ? (
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <InputLabel htmlFor='buttons'>Valitse liikuntaraha</InputLabel>
                  <Button
                    onClick={() => setSportmoney('epassi')}
                    variant={sportmoney === 'epassi' ? 'outlined' : ''}
                    style={{ marginRight: 20 }}
                  >
                    {<img height='30px' src={epassilogo} alt='ePassi' />}
                  </Button>
                  <Button
                    onClick={() => setSportmoney('smartum')}
                    variant={sportmoney === 'smartum' ? 'outlined' : ''}
                    style={{ marginRight: 20 }}
                  >
                    {<img height='30px' src={smartumlogo} alt='Smartum' />}
                  </Button>
                  {/* <Button
                    onClick={() => setSportmoney('eazybreak')}
                    variant={sportmoney === 'eazybreak' ? 'outlined' : ''}
                  >
                    {<img height='30px' src={eazybreaklogo} alt='Eazybreak' />}
                  </Button> */}
                </Grid>
              ) : null}
              {sportmoney ? (
                <React.Fragment>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id='invoiceid'
                      label='Laskunnumero'
                      variant='outlined'
                      value={invoiceid}
                      onChange={(e) => setInvoiceid(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id='reference'
                      label='Laskun viite'
                      variant='outlined'
                      value={reference}
                      onChange={(e) => setReference(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id='name'
                      label='Maksajan nimi'
                      variant='outlined'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id='email'
                      label='Maksajan sähköpostiosoite'
                      variant='outlined'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id='phone'
                      label='Maksajan puhelin'
                      variant='outlined'
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl sx={{ width: '100%' }} variant='outlined' required>
                      <InputLabel htmlFor='amount'>Summa</InputLabel>
                      <OutlinedInput
                        fullWidth
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        id='amount'
                        label='Summa'
                        variant='outlined'
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        endAdornment={<InputAdornment position='end'>€</InputAdornment>}
                        required
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment>
              ) : (
                ''
              )}
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ marginTop: 25, marginBottom: 25 }}
              direction='row'
              justifyContent='center'
              alignItems='center'
              textAlign='center'
            >
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <EpassiButton
                  fullWidth
                  variant='contained'
                  disabled={!valid}
                  size='large'
                  onClick={(e) => {
                    savePaymentDraft(e, sportmoney)
                  }}
                >
                  Maksa
                </EpassiButton>
              </Grid>
              {/* <Grid item xs={6} style={{ textAlign: 'center' }}>
                <SmartumButton
                  variant='contained'
                  disabled={!valid}
                  size='large'
                  onClick={(e) => {
                    savePaymentDraft(e, 'smartum')
                  }}
                >
                  Smartum
                </SmartumButton>
              </Grid> */}
            </Grid>
            {showDebug ? (
              <Grid>
                <Alert severity={errors ? 'error' : 'info'}>{debug}</Alert>
              </Grid>
            ) : null}
          </Grid>
        </Paper>
      </Container>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={saving}
        onClick={() => setSaving(false)}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </React.Fragment>
  )
}

export default HomeScreen
