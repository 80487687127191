import React from 'react'
import HomeScreen from './screens/HomeScreen'
import DoneScreen from './screens/DoneScreen'
import NotpaidScreen from './screens/NotpaidScreen'
import { Routes, Route } from 'react-router'

const AppNavigator = () => {
  return (
    <Routes>
      <Route path='/done' element={<DoneScreen/>} />
          {/* <DoneScreen />
        </Route> */}
        <Route path='/notpaid' element={<NotpaidScreen />} />
          {/* <NotpaidScreen />
        </Route> */}
      <Route path='/' element={<HomeScreen />} />
        {/* <HomeScreen />
      </Route> */}
    </Routes>
  )
}

export default AppNavigator
