import React from 'react'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Slide from '@mui/material/Slide'
import { useNavigate } from 'react-router-dom'

function NotpaidScreen() {
  const containerRef = React.useRef(null)
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth='sm'>
        <Grid
          container
          spacing={0}
          direction='column'
          alignItems='center'
          justify='center'
          style={{ minHeight: '100vh' }}
        >
          <Grid item xs={3}>
          <Slide direction="up" in={true} container={containerRef.current}>
            <Card sx={{ maxWidth: 345 }}>
                <CardContent>
                  <div className='donewrapper'>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 98.5 98.5" enableBackground="new 0 0 98.5 98.5">
  <path className="failmark" fill="none" strokeWidth="8" strokeMiterlimit="10" d="M81.7,17.8C73.5,9.3,62,4,49.2,4
	C24.3,4,4,24.3,4,49.2s20.3,45.2,45.2,45.2s45.2-20.3,45.2-45.2c0-8.6-2.4-16.6-6.5-23.4l0,0L45.6,68.2L24.7,47.3"/>
</svg></div>
                </CardContent>
                <CardContent>
                <Typography gutterBottom variant='h5' component='div'>
                  Maksua ei suoritettu
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  Maksu ei onnistunut.
                </Typography>
              </CardContent>
              <CardActions>
                <Button size='small' onClick={() => navigate('/')}>Uusi maksu</Button>
              </CardActions>
              </Card>
              </Slide>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  )
}

export default NotpaidScreen
