import React from 'react'
import AppNavigator from './AppNavigator'
import { BrowserRouter } from 'react-router-dom'
import './App.css'

function App() {
  return (
    <BrowserRouter>
      <AppNavigator />
    </BrowserRouter>
  )
}

export default App
